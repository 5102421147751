import * as React from 'react';
import clsx from 'clsx';
import {
    Box,
    Grid,
    Divider,
    Paper,
    Typography,
    Theme,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    List,
    ListItem,
    Radio,
    Snackbar,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MuiAlert from '@mui/material/Alert';

import Modal from 'components/Modal/Modal';
import VoucherDetailsActions from './VoucherDetailsActions';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import BackdropSpinner from 'components/Common/BackdropSpinner';
import VoucherDetailsHeader from './VoucherDetailsHeader';

import RefreshIcon from '@mui/icons-material/Refresh';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import useStyles from './styles';
import { DETAILS_REQUEST_INTERVAL, DETAILS_REFRESH_REQUEST_TYPE } from 'const';
import {
    voucherDetailsMainDetails,
    voucherDetailsAuthorizeReprintReason,
    detailsAuthorizeReprintKeysForCheck,
} from 'data';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';
import { formatDateByPattern, formatToIsoDateString } from 'utils/formatDate';

import { cancelRequests, isCanceled } from 'utils/requestCancelation';

import { hasKeyWithTrueValue } from 'utils/objectMethods';

import {
    getPropertyValue,
} from 'pages/Reports/Tickets/TicketDetails/business';

import { withRouter } from 'react-router-dom';

import { IVoucherDetailsModel } from 'api/models/reports';

import Api from 'api/Api';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': { borderBottom: 0 },
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' }
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        minHeight: 68,
    },
    content: {
        margin: '0',
        alignItems: 'center',
        '&$expanded': {
            margin: '0'
        },

        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap'
        }
    },
    expanded: {
        backgroundColor: theme.palette.grey[100],

        '&:hover': {
            cursor: 'auto'
        }
    },
    expandIcon: {
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            top: theme.spacing(2),
            right: theme.spacing(2),
        }
    },
    modalBox: {
        padding: `0 ${theme.spacing(3)}`,
        marginBottom: theme.spacing(2),
    },
    inputWrap: {
        '& .Mui-focused': {
            color: `${theme.palette.primary.main}`,
        }
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
}))(MuiAccordionDetails);

const { useState, useContext, useEffect } = React;

const apiRequests = {
    details: null,
    autorizeReprint: null,
    refreshDetails: null,
};

export const VoucherDetails = (props) => {
    const { history, match } = props;
    const { globalSettings, translations, dateTimeFormat, timeZone }: GlobalContextModel = useContext(GlobalContext);
    const classes = useStyles({});

    const [data, setData] = useState<IVoucherDetailsModel>({} as IVoucherDetailsModel);
    const [ isLoaded, setIsLoaded ] = useState(false);

    const [ isOpenReprintAuthorizationModal, setIsOpenReprintAuthorizationModal ] = useState(false);
    const [ authorizeReprintReason, setAuthorizeReprintReason ] = useState(0);

    const [ showNotification, setShowNotification ] = useState(false);
    const [ requestStatus, setRequestStatus ] = useState('success');

    useEffect(() => {
        match.params.id && loadData();

        const interval = setInterval(loadData, DETAILS_REQUEST_INTERVAL);

        return () => {
            interval && clearInterval(interval);
        };
    }, [match.params.id]);

    useEffect(() => {
        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = async () => {
        try {
            apiRequests.details = Api.VouchersReport.GetVouchersDetails(match.params.id);

            const data = await apiRequests.details as IVoucherDetailsModel;

            data && setIsLoaded(true);
            data && setData(data);
        } catch (error) {
            // handle fetch errors herev
            setIsLoaded(true);
            !isCanceled(error) && setIsLoaded(true);
        }
    };

    const handleReprintAuthorization = () => {
        setIsOpenReprintAuthorizationModal(true);
    };

    const onCloseReprintAuthorizationModal = () => {
        setIsOpenReprintAuthorizationModal(false);
    };

    const handleSubmitReprintAuthorization = async () => {
        setIsOpenReprintAuthorizationModal(false);

        apiRequests.autorizeReprint = Api.VouchersReport.AutorizeReprint({
            printoutId: String(data.voucherId),
            authorizeReprintReason,
            authorizeReprintComment: '',
            isCMV: data.isCMV
        });

        apiRequests.refreshDetails = Api.VouchersReport.RefreshDetails({
            voucherId: data.voucherId,
            refreshType: DETAILS_REFRESH_REQUEST_TYPE.REPRINT_AUTHORIZATION
        });

        try {
            const result = await apiRequests.autorizeReprint;

            if (result) {
                setRequestStatus('success');
                setShowNotification(true);

                await new Promise(resolve => setTimeout(resolve, 200));

                const refreshedDetails = await apiRequests.refreshDetails;

                refreshedDetails && setData(refreshedDetails);
            }
        } catch (error) {
            setRequestStatus('error');
            setShowNotification(true);
        }
    };

    const handleCloseNotification = () => setShowNotification(false);

    return (
        <div className={classes.root}>
            {!isLoaded ? <BackdropSpinner open={!isLoaded} /> : (
                <>
                    <div className={classes.header}>
                        <VoucherDetailsHeader
                            history={history}
                            data={{
                                voucherId: data.voucherId,
                                isCMV: data.isCMV
                            }}
                        />
                    </div>
                    <Divider />
                    <Paper className={classes.bodyWrap} elevation={0}>
                        <BackdropSpinner open={!isLoaded} />
                        <VoucherDetailsActions
                            handleReprintAuthorization={handleReprintAuthorization}
                        />
                        <div className={classes.bodyWrapContainer}>
                            <Box className={classes.content}>
                                <Grid container spacing={3} alignItems="flex-start">
                                    <Grid item md={12} style={{ width: '100%' }}>
                                        <Accordion id={'main'} expanded >
                                            <AccordionSummary
                                                expandIcon={<span />}
                                            >
                                                <div className={classes.mainBlockHeadCell} data-a="voucher-cost">
                                                    <Typography variant="body2" className={classes.mainBlockHeadCellName} noWrap >
                                                        {translations['emp-reports-voucher-cost']}
                                                    </Typography>
                                                    <Typography variant={'h5'} noWrap>
                                                        {formatNumberToCurrencyString(
                                                            data.cost,
                                                            numberStyles(globalSettings?.settings?.Currency),
                                                            globalSettings?.settings?.CurrencySign,
                                                            globalSettings?.settings?.CurrencyFormat
                                                        )}
                                                    </Typography>
                                                </div>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Box padding={3} className={clsx(classes.blockDetailsContainer, classes.mainBlockDetailsContainer)}>
                                                    {voucherDetailsMainDetails.map((property, id) => {
                                                        return (
                                                            <div key={id} data-a={`voucher-${id}`} className={classes.mainBlockDetailsBox}>
                                                                <Typography
                                                                    variant="body2"
                                                                    className={classes.mainBlockDetailsName}
                                                                >
                                                                    {translations[property.key]}
                                                                </Typography>
                                                                <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                    {getPropertyValue(property, data, dateTimeFormat, timeZone, globalSettings)}
                                                                </Typography>
                                                            </div>
                                                        );
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                        {
                                            data?.printouts?.some(printout => hasKeyWithTrueValue(printout, detailsAuthorizeReprintKeysForCheck)) &&
                                                <Accordion expanded >
                                                    <AccordionSummary
                                                        expandIcon={<span />}
                                                    >
                                                        <Box className={classes.iconBox}>
                                                            <RefreshIcon color="primary" />
                                                        </Box>
                                                        <Box className={classes.reprintBox}>
                                                            <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1}>
                                                                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                                                                    {translations['emp-reports-ticket-authorize-reprint-details']}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </AccordionSummary>
                                                    {data?.printouts?.map((printout, id) => {
                                                        return (
                                                            <AccordionDetails
                                                                key={`printout-${id}`}
                                                                id={`printout-${id}`}
                                                                className={classes.secondaryDetailsBox}
                                                            >
                                                                <Box padding={3} className={classes.blockDetailsFull}>
                                                                    <div className={classes.mainBlockDetailsBox}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={classes.mainBlockDetailsName}
                                                                        >
                                                                            {translations['emp-reports-ticket-reprint-authorization-date']}
                                                                        </Typography>
                                                                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                            {
                                                                                printout?.lastAuthorizedDate
                                                                                    ? formatDateByPattern(
                                                                                        new Date(formatToIsoDateString(printout.lastAuthorizedDate)),
                                                                                        dateTimeFormat,
                                                                                        timeZone
                                                                                    )
                                                                                    : '-'
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.mainBlockDetailsBox}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={classes.mainBlockDetailsName}
                                                                        >
                                                                            {translations['emp-reports-ticket-authorized-by']}
                                                                        </Typography>
                                                                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                            {
                                                                                printout?.lastAuthorizedEntityName ?? '-'
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.mainBlockDetailsBox}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={classes.mainBlockDetailsName}
                                                                        >
                                                                            {translations['emp-reports-ticket-reason']}
                                                                        </Typography>
                                                                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                            {
                                                                                printout?.authorizeReprintReason
                                                                                    ? translations[
                                                                                        voucherDetailsAuthorizeReprintReason.find(
                                                                                            reason => reason.id === printout.authorizeReprintReason
                                                                                        )['label']
                                                                                    ]
                                                                                    : '-'
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.mainBlockDetailsBox}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={classes.mainBlockDetailsName}
                                                                        >
                                                                            {translations['emp-reports-ticket-reprinted-date']}
                                                                        </Typography>
                                                                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                            {
                                                                                printout?.lastPrintDate
                                                                                    ? formatDateByPattern(
                                                                                        new Date(formatToIsoDateString(printout.lastPrintDate)),
                                                                                        dateTimeFormat,
                                                                                        timeZone
                                                                                    )
                                                                                    : '-'
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.mainBlockDetailsBox}>
                                                                        <Typography
                                                                            variant="body2"
                                                                            className={classes.mainBlockDetailsName}
                                                                        >
                                                                            {translations['emp-reports-ticket-reprinted-by']}
                                                                        </Typography>
                                                                        <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                            {
                                                                                printout?.lastPrintEntityName ?? '-'
                                                                            }
                                                                        </Typography>
                                                                    </div>
                                                                </Box>
                                                            </AccordionDetails>);
                                                    })
                                                    }
                                                </Accordion>
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Paper>
                    <Modal isOpen={isOpenReprintAuthorizationModal} onClose={onCloseReprintAuthorizationModal} data-a="reprint-authorization-modal">
                        <DialogTitle id="alert-dialog-title">{translations['emp-reports-voucher-authorize-reprint-modal-title']}</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <DialogContentText id="alert-dialog-description">
                                {translations['emp-reports-voucher-authorize-reprint-modal-reason']}
                            </DialogContentText>
                        </DialogContent>
                        <Box className={classes.modalBox} mb={1}>
                            <List disablePadding>
                                {voucherDetailsAuthorizeReprintReason.map((reason) => (
                                    <ListItem
                                        className={classes.listItem}
                                        key={reason.id}
                                        dense
                                        onClick={() => setAuthorizeReprintReason(reason.id)}
                                    >
                                        <Radio
                                            color={'primary'}
                                            checked={reason.id === authorizeReprintReason}
                                            name="item.id"
                                        />
                                        {translations[reason.label]}
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        <DialogActions>
                            <ButtonPrimaryText onClick={onCloseReprintAuthorizationModal} data-a="emp-reports-ticket-authorize-reprint-modal-button-no">
                                {translations['gen-close']}
                            </ButtonPrimaryText>
                            <ButtonPrimaryText
                                onClick={handleSubmitReprintAuthorization}
                                data-a="emp-reports-ticket-authorize-reprint-modal-button-yes"
                                disabled={!authorizeReprintReason}
                            >
                                {translations['gen-proceed']}
                            </ButtonPrimaryText>
                        </DialogActions>
                    </Modal>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={showNotification}
                        autoHideDuration={6000}
                        onClose={handleCloseNotification}
                    >
                        <MuiAlert
                            onClose={handleCloseNotification}
                            severity={requestStatus === 'success' ? 'success' : 'error'}
                            elevation={6}
                            variant="filled"
                        >
                            {translations[
                                requestStatus === 'success'
                                    ? 'emp-reports-voucher-authorize-reprint-success'
                                    : 'emp-reports-ticket-authorize-reprint-error'
                            ]}
                        </MuiAlert>
                    </Snackbar>
                </>)}
        </div>
    );
};

export default withRouter(VoucherDetails);
