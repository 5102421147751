import * as React from 'react';
import clsx from 'clsx';
import {
    DialogContent,
    DialogTitle,
    DialogActions,
    Typography,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Radio
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { List, AutoSizer } from 'react-virtualized';
import TestLabel from 'components/TestLabel/TestLabel';
import Modal from 'components/Modal/Modal';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { sortingBySearch, sortingByInitialCheckedWithSearch } from '../utils';

const { useEffect, useState, useContext, useRef, useCallback } = React;

const useStyles = makeStyles((theme: Theme) => ({
    groupLabel: {
        marginTop: 0,
        marginBottom: theme.spacing(2),
        fontSize: '1.5rem',
        fontWeight: 500,

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        },
    },
    groupContent: {
        height: '50vh'
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    disabledMenuItem: {
        '&:hover': {
            backgroundColor: 'initial'
        }
    },
    menuItemRow: {
        overflow: 'hidden',
        marginRight: 0,

        '& .MuiFormControlLabel-label ': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    },
    testLabel: {
        marginLeft: theme.spacing(0.5),
    },
    actionsLabel: {
        flex: '1 1 auto',
        paddingLeft: theme.spacing(1)
    }
}));

const CheckboxRadioListWithSearchModal = (
    props: {
        readOnly?: boolean;
        isOpen: boolean;
        data: Array<any>;
        selectedIds: Array<number>;
        onClose: () => void;
        onConfirm: (groupIds: Array<number>) => void;
        translations?: {
            dialogTitle?: string;
            actionsLabel?: string;
            singleActionLabel?: string;
            noItemsLabel?: string;
        };
        multiple?: boolean;
        onlyOneChange?: boolean;
        isPermissionMessageShow?: boolean;
    }) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = useContext(GlobalContext);
    const { data, selectedIds, readOnly = false, isOpen, onClose, onConfirm, multiple = true, onlyOneChange = false,
        isPermissionMessageShow = false } = props;

    const [searchText, setSearchText] = useState('');
    const [selectedIDsInit, setSelectedIDsInit] = useState([]);
    const [selectedIDsState, setSelectedIDsState] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const listRef = useRef(null);
    const [hiddenCellHeight, setHiddenCellHeightRef] = useState(0);

    const hiddenCellRef = useCallback(node => {
        if (node !== null) {
            setHiddenCellHeightRef(node.getBoundingClientRect().height);
        }
    }, []);

    useEffect(() => {
        setSelectedIDsInit([...selectedIds]);
        setSelectedIDsState([...selectedIds]);
        setFilteredData(sortingByInitialCheckedWithSearch(data, selectedIds, searchText));
    }, [selectedIds, data]);

    useEffect(() => {
        isOpen && setFilteredData(sortingByInitialCheckedWithSearch(data, selectedIds, searchText));
    }, [isOpen]);

    const resetValues = () => {
        setSearchText('');
        setFilteredData(sortingByInitialCheckedWithSearch(data, selectedIDsInit, ''));
    };

    const handleConfirm = () => {
        resetValues();
        setSelectedIDsInit([...selectedIDsState]);
        onConfirm(selectedIDsState.sort((a, b) => a - b));
    };

    const handleClose = () => {
        setSelectedIDsInit([...selectedIds]);
        setSelectedIDsState([...selectedIds]);
        resetValues();
        onClose();
    };

    const onSearchChange = (event) => {
        if (searchText !== event.target.value) {
            setSearchText(event.target.value);
            setFilteredData(sortingByInitialCheckedWithSearch(data, selectedIDsInit, event.target.value));
        }
    };

    const checkboxClick = (id) => {
        let idxInState = selectedIDsState.indexOf(id);
        let idxInInit = selectedIDsInit.indexOf(id);

        selectedIDsInit.includes(id) && selectedIDsInit.splice(idxInInit, 1);

        selectedIDsState.includes(id)
            ? selectedIDsState.splice(idxInState, 1)
            : selectedIDsState.push(id);

        setSelectedIDsInit([...selectedIDsInit]);
        setSelectedIDsState([...selectedIDsState]);

        setFilteredData(sortingByInitialCheckedWithSearch(data, selectedIDsInit, searchText));
    };

    const radioClick = (id) => {
        setSelectedIDsState([id]);
        setFilteredData(sortingBySearch(data, searchText));
    };

    const renderRow = ({ index, style, forwardRef = null }) => {
        const group = filteredData[index] || {};

        return (
            <MenuItem
                key={group.id}
                ref={forwardRef}
                className={clsx(classes.menuItem, readOnly && classes.disabledMenuItem)}
                style={style}
                id={group.id}
                onClick={(event) => {
                    event.preventDefault();
                    multiple ? !readOnly && checkboxClick(group.id) : !readOnly && radioClick(group.id);
                }}
                data-a="groups-modal-row"
            >
                <FormControlLabel
                    className={classes.menuItemRow}
                    value={group.name}
                    control={
                        multiple
                            ? (
                                <Checkbox
                                    color="primary"
                                    checked={selectedIDsState.includes(group.id)}
                                    onClick={(event) => event.preventDefault()}
                                />)
                            : (
                                <Radio
                                    color="primary"
                                    checked={selectedIDsState.includes(group.id)}
                                    onClick={(event) => event.preventDefault()}
                                />)
                    }
                    disabled={readOnly}
                    label={group.name}
                />
                {group.isTest && <TestLabel className={classes.testLabel} />}
            </MenuItem>
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            fullWidth
            data-a="groups-modal"
        >
            { // render single invisible row for virtual list height measuremnt
                renderRow({
                    index: 0,
                    style: { visibility: 'hidden', position: 'absolute' },
                    forwardRef: hiddenCellRef
                })
            }
            <DialogTitle id="alert-dialog-title">
                <p className={classes.groupLabel}>{props.translations?.dialogTitle || translations['groups-select-betshops']}</p>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder={translations['hm-search']}
                    InputProps={{
                        startAdornment: <SearchIcon />,
                        endAdornment: searchText && <CloseIcon onClick={resetValues} style={{ cursor: 'pointer' }} />
                    }}
                    onChange={onSearchChange}
                    value={searchText}
                    fullWidth
                />
            </DialogTitle>
            <DialogContent dividers className={classes.groupContent}>
                {filteredData?.length
                    ? (
                        <AutoSizer>
                            { ({ width, height }) => (
                                <List
                                    ref={listRef}
                                    width={width}
                                    height={height}
                                    rowHeight={hiddenCellHeight}
                                    rowRenderer={renderRow}
                                    rowCount={filteredData.length}
                                    overscanRowCount={25}
                                />
                            )}
                        </AutoSizer>
                    )
                    : props.translations?.noItemsLabel || translations['groups-no-betshops-found']
                }
            </DialogContent>
            <DialogActions>
                {multiple
                    ? (
                        <Typography color="primary" variant="caption" className={classes.actionsLabel}>
                            {selectedIDsState.length} {selectedIDsState?.length > 1
                                ? props.translations?.actionsLabel || translations['groups-x-betshops-selected']
                                : props.translations?.singleActionLabel || translations['groups-betshop-selected']
                            }
                        </Typography>)
                    : (onlyOneChange &&
                        <Typography color="textPrimary" variant="caption" className={classes.actionsLabel}>{translations['users-cannot-be-changed']}</Typography>)
                }
                {readOnly && isPermissionMessageShow &&
                    <Typography color="textPrimary" variant="caption" className={classes.actionsLabel}>{translations['users-have-not-permission-to-change-sportsbook']}</Typography>
                }
                <>
                    <ButtonPrimaryText data-a="groups-modal-cancel" onClick={handleClose}>
                        {translations['gen-cancel']}
                    </ButtonPrimaryText>
                    { !readOnly && (
                        <ButtonPrimaryText data-a="groups-modal-add" onClick={handleConfirm}>
                            {translations['users-edit']}
                        </ButtonPrimaryText>
                    )}
                </>
            </DialogActions>
        </Modal>
    );
};

export default CheckboxRadioListWithSearchModal;
