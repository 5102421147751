import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import { toggleItemInArray } from 'utils/arrayMethods';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        minWidth: '420px',

        [theme.breakpoints.down('sm')]: {
            minWidth: '320px',
        }
    },
    listItem: {
        padding: theme.spacing(0.5, 0)
    },
    button: {
        textTransform: 'capitalize'
    }
}));

const ListModal = (
    props: {
        data: Array<string>;
        dataChecked: Array<string>;
        isOpen: boolean;
        title: string;
        confirmTextKey: string;
        cancelTextKey: string;
        onClose: () => void;
        onConfirm: (ids: Array<string>) => void;
        keyToTranslations?: string;
        radio?: boolean;
        disabledApproveButton?: boolean;
    }) => {
    const {
        data,
        dataChecked,
        isOpen,
        title,
        confirmTextKey,
        cancelTextKey,
        onClose,
        onConfirm,
        keyToTranslations,
        radio = false,
        disabledApproveButton = false
    } = props;

    const { translations }: GlobalContextModel = React.useContext(GlobalContext);
    const classes = useStyles();

    const [selected, setSelected] = React.useState<Array<string>>([]);

    React.useEffect(() => {
        setSelected(dataChecked);
    }, [dataChecked]);

    const handleToggle = (name: string) => () => {
        const selectedIndex = selected.findIndex(item => item === name);
        const newSelected = toggleItemInArray(name, selectedIndex, selected);

        setSelected(newSelected);
    };

    const handleToggleRadio = (name: string) => () => {
        setSelected([name]);
    };

    function handleClick() {
        onConfirm(selected);
    }

    const handleClose = () => {
        onClose();
    };

    return ReactDOM.createPortal(
        <div className={classes.wrap}>
            <Dialog
                classes={{ paper: classes.wrap }}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title && translations[title]}</DialogTitle>
                <DialogContent>
                    <List disablePadding>
                        {
                            data?.map((name, i) =>
                                (
                                    <ListItem data-a={`list-modal-item-${i}`} className={classes.listItem} key={i} dense>
                                        <FormControlLabel
                                            control={
                                                radio
                                                    ? (
                                                        <Radio
                                                            color="primary"
                                                            onChange={handleToggleRadio(name)}
                                                            checked={selected.some(item => item === name)}
                                                        />)
                                                    : (
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={handleToggle(name)}
                                                            checked={selected.some(item => item === name)}
                                                        />)
                                            }
                                            label={keyToTranslations ? translations[`${keyToTranslations}-${name}`] : name}
                                        />
                                    </ListItem>))
                        }
                    </List>
                </DialogContent>
                <DialogActions>
                    <ButtonPrimaryText data-a="list-modal-button-cancel" className={classes.button} onClick={handleClose} >
                        {translations[cancelTextKey]}
                    </ButtonPrimaryText>
                    <ButtonPrimaryText data-a="list-modal-button-add" className={classes.button} onClick={handleClick} disabled={disabledApproveButton}>
                        {translations[confirmTextKey]}
                    </ButtonPrimaryText>
                </DialogActions>
            </Dialog>
        </div>,
        document.getElementById('modal')
    );
};

export default ListModal;
