import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { throwNetworkError } from 'utils/requestCancelation';
import { getQueryParams } from 'utils/devUtils';

import Api from 'api/Api';

import App from './App';
import defaultTheme from 'theme';

const styles = () => ({
    '@global': {
        'html': {
            height: '100%',
        },
        'body': {
            margin: 0,
            fontFamily: 'Roboto',
            height: '100%',
        },
        '#root': {
            height: '100%',
        },
        '@keyframes dot-flashing': {
            '0%': {
                backgroundColor: defaultTheme.palette.grey[200]
            },
            '75%': {
                backgroundColor: defaultTheme.palette.grey[400]
            },
            '100%': {
                backgroundColor: defaultTheme.palette.grey[400]
            }
        },
    }
});

window['createCookie'] = function (key, value) {
    document.cookie = `${key}=${value}`;
};

window['removeCookie'] = function (name) {
    document.cookie = `${name}=`;
};

const AppRootWithStyles = withStyles(styles)(App);

const isLoginUri = document.location.href.includes('state=') && document.location.href.includes('code=');
const rootElement = document.getElementById('root');

const GetLoginUri = (cleanRestorePath = false) => {
    Api.General.GetLoginUri(cleanRestorePath).then(res => {
        document.location.href = res.loginUri;
    }).catch(throwNetworkError);
};

if (isLoginUri) {
    let restorePath = getQueryParams(document.location.href)?.['restorePath'];
    const root = createRoot(rootElement);

    Api.General.GetToken('POST', restorePath).then(() => {
        if (isLoginUri) history.pushState({}, null, restorePath ? restorePath : '/');

        rootElement && root.render(
            <BrowserRouter>
                <CssBaseline />
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={defaultTheme}>
                        <AppRootWithStyles />
                    </ThemeProvider>
                </StyledEngineProvider>
            </BrowserRouter>);
    }).catch(() => {
        GetLoginUri(true);
    });
} else {
    GetLoginUri();
}

export const Logout = new BroadcastChannel('Logout');

Logout.addEventListener('message', () => {
    Api.General.GetToken('DELETE');
});
