import * as React from 'react';
import { DialogContent, DialogContentText, DialogActions, DialogTitle } from '@mui/material';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import Modal from 'components/Modal/Modal';
import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

const NotificationModal = (
    props: {
        isOpen: boolean;
        title: string;
        description: string;
        onClose: () => void;
    }) => {
    const { isOpen, title, description, onClose } = props;
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <DialogTitle id="alert-dialog-title">{title && translations[title]}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description && translations[description]}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonPrimaryText
                    onClick={onClose}
                    data-a="notification-modal-submit-button"
                >
                    {translations['gen-ok']}
                </ButtonPrimaryText>
            </DialogActions>
        </Modal>
    );
};

export default NotificationModal;
