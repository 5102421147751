import * as React from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import {
    Checkbox,
    MenuItem,
    Typography,
    Accordion,
    AccordionDetails,
    FormControlLabel,
    Tooltip,
    Radio
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

import StyledAccordionSummary from '../StyledAccordionSummary/StyledAccordionSummary';
import { IFilterListItem } from 'const';
import { sortByValuesThenNames } from 'utils/arrayMethods';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';
import { IFilterDublicateEmailItem } from 'api/models/user';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
    },
    titleText: {
        paddingRight: theme.spacing(0.5)
    },
    accordion: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        '&.Mui-expanded': {
            margin: 0
        },
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: '0 auto',
        },
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: `0 0 ${theme.spacing(1.5)}`,
    },
    menuItem: {
        lineHeight: 1,
        padding: `0 ${theme.spacing(2)}`,
        '& .MuiTypography-body1': {
            fontSize: '0.875rem'
        }
    },
    menuItemRow: {
        width: '100%',
        overflow: 'hidden',

        '& > .MuiTypography-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    emptyMessage: {
        padding: theme.spacing(3, 2)
    }
}));

type IExtendedData = Array<IFilterListItem | number>;

interface IActiveFilterIds {
    [key: string]: IExtendedData;
}

export interface IFiltersBoxProps {
    label: string;
    title: string;
    translationKey: string;
    expanded: boolean;
    data: IExtendedData;
    activeFilterIds: IActiveFilterIds;
    tooltip?: string;
    handleFilterClick: (key: string, item: IFilterListItem) => void;
    handleExpanded: (key: string) => void;
    children?: React.ReactNode;
}

export const Filters: React.FunctionComponent<Partial<IFiltersBoxProps>> = (props) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const { label, title, expanded, activeFilterIds, handleExpanded, tooltip } = props;

    const onExpansionClick = () => {
        handleExpanded(label);
    };

    return (
        <Accordion
            className={classes.accordion}
            defaultExpanded={!!activeFilterIds?.[label].length}
            expanded={expanded || false}
            data-a={label}
        >
            <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
                onClick={onExpansionClick}
            >
                <Typography className={classes.title} variant="h6">
                    <span className={classes.titleText}>{translations[title]}</span>
                    {tooltip &&
                        <Tooltip title={translations[tooltip]} data-a={tooltip}>
                            <InfoOutlined fontSize="small" data-a={`${tooltip}-icon`} />
                        </Tooltip>
                    }
                </Typography>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.accordionDetails} >
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
};

const TerminalData = ({ data, label, translationKey, activeFilterIds, handleFilterClick }) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const isChecked = (id: number): boolean => {
        return activeFilterIds?.[label]?.some((e) => e.id === id);
    };

    return (
        <>
            {data.map((item) => {
                return (
                    <MenuItem
                        className={classes.menuItem}
                        dense
                        key={`${label + item.name}`}
                        data-a={`${label + item.name}`}
                        onClick={(event) => {
                            event.preventDefault();
                            handleFilterClick(label, item);
                        }}
                    >
                        <FormControlLabel
                            className={classes.menuItemRow}
                            value={item.name}
                            data-a={`filter-checkbox-${item.name}`}
                            control={
                                <Checkbox
                                    color="primary"
                                    onClick={(event) => event.preventDefault()}
                                    checked={isChecked(item.id)}
                                />
                            }
                            label={translationKey ? translations[`${translationKey}-${item.name}`] : item.name}
                        />
                    </MenuItem>
                );
            })}
        </>
    );
};

const FiltersItem = ({ id, name, isChecked, count, handleFilterClick }) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    return (
        <MenuItem
            className={classes.menuItem}
            dense
            key={name}
            data-a={name}
            onClick={(event) => {
                event.preventDefault();
                handleFilterClick(id);
            }}
        >
            <FormControlLabel
                className={classes.menuItemRow}
                value={name}
                data-a={`filter-radio-${name}`}
                control={
                    <Radio
                        color="primary"
                        onClick={(event) => event.preventDefault()}
                        checked={isChecked}
                    />
                }
                label={name}
            />
            <span>{`${count} ${translations['gen-users'].toLocaleLowerCase()}`}</span>
        </MenuItem>
    );
};

const FiltersList = ({ data, label, activeFilters, appliedFilters, handleFilterClick }) => {
    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const isChecked = (item: IFilterDublicateEmailItem): boolean => {
        return activeFilters[label].some(activeFilter => activeFilter.emailHash === item.emailHash);
    };

    return (
        <>
            {appliedFilters[label].map((item) => {
                return (
                    <FiltersItem
                        key={item.emailHash}
                        id={item.emailHash}
                        name={item.email}
                        count={item.count}
                        handleFilterClick={handleFilterClick}
                        isChecked={isChecked(item)}
                    />);
            })}
            {sortByValuesThenNames(data[label]).map((item) => {
                const showItem = !appliedFilters[label].some(appliedFilter => appliedFilter.emailHash === item.emailHash);

                return (
                    showItem &&
                        <FiltersItem
                            key={item.emailHash}
                            id={item.emailHash}
                            name={item.email}
                            isChecked={isChecked(item)}
                            count={item.count}
                            handleFilterClick={handleFilterClick}
                        />);
            })}
            {!data[label].length &&
                <Typography className={classes.emptyMessage}>{translations['users-dublicate-emails-empty-description']}</Typography>
            }
        </>
    );
};

export const FiltersBox = (props: IFiltersBoxProps) => {
    return (
        <Filters {...props} >
            <TerminalData {...props} />
        </Filters>
    );
};

export const FiltersBoxWithCount = (props) => {
    return (
        <Filters {...props} tooltip="users-dublicate-emails-info">
            <FiltersList {...props} />
        </Filters>
    );
};
