/* eslint-disable max-len */
import * as React from 'react';
import clsx from 'clsx';
import {
    Box,
    Grid,
    Divider,
    Paper,
    Typography,
    Theme,
    Button,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
    List,
    ListItem,
    Radio,
    Snackbar,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MuiAlert from '@mui/material/Alert';

import Modal from 'components/Modal/Modal';
import TicketDetailsActions from './TicketDetailsActions';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { BadgeIcon, SgpxIcon, ProgIcon } from 'components/Badges/Badges';
import BackdropSpinner from 'components/Common/BackdropSpinner';
import TicketDetailsHeader from './TicketDetailsHeader';
import TicketDetailsTable from './TicketDetailsTable/TicketDetailsTable';
import TicketDetailsPrintout from './TicketDetailsBlocks/TicketDetailsPrintout';
import TicketDetailsCancel from './TicketDetailsBlocks/TicketDetailsCancel';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ButtonPrimaryText from 'components/Buttons/ButtonPrimaryText';

import useStyles from './styles';
import { DETAILS_REQUEST_INTERVAL, DETAILS_REFRESH_REQUEST_TYPE, BET_STATUS, CANCEL_REASONS, initRequestInfo, BET_TYPE } from 'const';
import {
    ticketDetailsMainDetails,
    ticketDetailsAuthorizeReprintReason,
    detailsAuthorizeReprintKeysForCheck,
    TicketVoidModal,
} from 'data';

import { hasKeyWithTrueValue } from 'utils/objectMethods';

import { GlobalContext } from 'context/globalContext';

import { formatNumberToCurrencyString, numberStyles } from 'utils/formatNumber';

import { cancelRequests, isCanceled } from 'utils/requestCancelation';

import {
    getBetDescriptionsString,
    getOddsTitle,
    getPropertyValue,
} from 'pages/Reports/Tickets/TicketDetails/business';

import { withRouter } from 'react-router-dom';

import { ITicketDetailsModel, IRequestInfo, IExpandedDetailsBlocks } from 'api/models/reports';
import Api from 'api/Api';

import { GlobalContextModel } from 'api/models/general';

import { setLanguageCookie } from 'utils/cookieMethods';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': { borderBottom: 0 },
        '&:before': { display: 'none' },
        '&$expanded': { margin: 'auto' }
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
    root: {
        minHeight: 68,
        '& .MuiAccordionSummary-expandIconWrapper': {
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('md')]: {
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
            },
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
            }
        },

        [theme.breakpoints.down('md')]: {
            '& .MuiAccordionSummary-expandIconWrapper': {
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
            },

        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiAccordionSummary-expandIconWrapper': {
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
            },

        }
    },
    content: {
        margin: '0',
        alignItems: 'center',
        '&$expanded': {
            margin: '0'
        },

        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap'
        }
    },
    expanded: {
        backgroundColor: theme.palette.grey[100]
    },
    modalBox: {
        padding: `0 ${theme.spacing(3)}`,
        marginBottom: theme.spacing(2),
    },
    inputWrap: {
        '& .Mui-focused': {
            color: `${theme.palette.primary.main}`,
        }
    },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
}))(MuiAccordionDetails);

const { useState, useContext, useEffect } = React;

const apiRequests = {
    details: null,
    autorizeReprint: null,
    ticketVoid: null,
    refreshDetails: null,
};

const initExpandedBlocks: IExpandedDetailsBlocks = {
    main: true,
    printout: true,
    cancel: true
};

export const TicketDetails = (props) => {
    const { history, match } = props;

    const { globalSettings, translations, dateTimeFormat, timeZone, permissions }: GlobalContextModel = useContext(GlobalContext);
    const classes = useStyles({});

    const [data, setData] = useState<ITicketDetailsModel>({} as ITicketDetailsModel);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ expandedBlock, setExpandedBlock ] = useState<IExpandedDetailsBlocks>({ ...initExpandedBlocks });

    const [ isOpenReprintAuthorizationModal, setIsOpenReprintAuthorizationModal ] = useState(false);
    const [ isOpenTicketVoidModal, setIsOpenTicketVoidModal ] = useState(false);
    const [ authorizeReprintReason, setAuthorizeReprintReason ] = useState(0);
    // const [ authorizeReprintComment, setAuthorizeReprintComment ] = useState('');
    const [ ticketVoidReasonId, setTicketVoidReasonId ] = useState(null);
    const [ showNotification, setShowNotification ] = useState(false);
    const [ requestInfo, setRequestInfo ] = useState<IRequestInfo>({ ...initRequestInfo });

    useEffect(() => {
        match.params.id && loadData();

        const interval = setInterval(loadData, DETAILS_REQUEST_INTERVAL);

        return () => {
            interval && clearInterval(interval);
        };
    }, [match.params.id]);

    useEffect(() => {
        return () => cancelRequests(apiRequests);
    }, []);

    const loadData = async () => {
        try {
            const payload = setLanguageCookie({ ticketId: match.params.id, betShopIds: [] }, globalSettings.languages, 'languageCode');

            apiRequests.details = Api.TicketsReport.GetTicketsDetails(payload);

            const data = await apiRequests.details as ITicketDetailsModel;

            data && setIsLoaded(true);
            data && setData(data);
        } catch (error) {
            !isCanceled(error) && setIsLoaded(true);
        }
    };

    const onExpansionClick = (name: keyof IExpandedDetailsBlocks) => setExpandedBlock({ ...expandedBlock, [name]: !expandedBlock[name] });

    const handleReprintAuthorization = () => {
        setIsOpenReprintAuthorizationModal(true);
    };

    const onCloseReprintAuthorizationModal = () => {
        setIsOpenReprintAuthorizationModal(false);
    };

    // const handleCommentChange = (event) => {setAuthorizeReprintComment(event.target.value);};

    const handleSubmitReprintAuthorization = async () => {
        setIsOpenReprintAuthorizationModal(false);

        const refreshDetailsPayload = setLanguageCookie(
            {
                ticketId: data.ticketId,
                refreshType: DETAILS_REFRESH_REQUEST_TYPE.REPRINT_AUTHORIZATION
            },
            globalSettings.languages,
            'languageCode'
        );

        apiRequests.autorizeReprint = Api.TicketsReport.AutorizeReprint({ printoutId: data.ticketId, authorizeReprintReason, authorizeReprintComment: '' });

        apiRequests.refreshDetails = Api.TicketsReport.RefreshDetails(refreshDetailsPayload);

        try {
            const result = await apiRequests.autorizeReprint;

            if (result) {
                setRequestInfo({
                    status: 'success',
                    message: 'emp-reports-ticket-authorize-reprint-success'
                });
                setShowNotification(true);

                await new Promise(resolve => setTimeout(resolve, 200));

                const refreshedDetails = await apiRequests.refreshDetails;

                refreshedDetails && setData(refreshedDetails);
            }
        } catch (error) {
            setRequestInfo({
                status: 'error',
                message: 'emp-reports-ticket-authorize-reprint-error'
            });
            setShowNotification(true);
        }
    };

    const handleSubmitTicketVoid = async () => {
        setIsOpenTicketVoidModal(false);

        apiRequests.ticketVoid = Api.TicketsReport.TicketVoid({ ticketId: data.ticketId, soxCancellationId: ticketVoidReasonId, comment: '' });

        apiRequests.refreshDetails = Api.TicketsReport.RefreshDetails({
            ticketId: data.ticketId,
            refreshType: DETAILS_REFRESH_REQUEST_TYPE.TICKET_VOID
        });

        try {
            const result = await apiRequests.ticketVoid;

            if (result) {
                setRequestInfo({
                    status: 'success',
                    message: 'emp-reports-ticket-void-success'
                });
                setShowNotification(true);

                await new Promise(resolve => setTimeout(resolve, 200));

                const refreshedDetails = await apiRequests.refreshDetails;

                refreshedDetails && setData(refreshedDetails);
            }
        } catch (error) {
            setRequestInfo({
                status: 'error',
                message: 'emp-reports-ticket-void-error'
            });
            setShowNotification(true);
        }
    };

    const openTiketVoid = () => {
        setIsOpenTicketVoidModal(true);
    };

    const onCloseTicketVoidModal = () => {
        setIsOpenTicketVoidModal(false);
    };

    const handleCloseNotification = () => setShowNotification(false);

    return (
        <div className={classes.root}>
            {!isLoaded ? <BackdropSpinner open={!isLoaded} /> : (
                <>
                    <div className={classes.header}>
                        <TicketDetailsHeader
                            history={history}
                            data={{
                                ticketId: data.ticketId,
                                betStatus: data.betStatus,
                                isPaid: !!data.paidDate,
                                isPrinted: data.isPrinted,
                                isPaidByCard: !!data.paymentExternalTransactionId
                            }}
                        />
                    </div>
                    <Divider />
                    <Paper className={classes.bodyWrap} elevation={0}>
                        <BackdropSpinner open={!isLoaded} />
                        <TicketDetailsActions
                            handleReprintAuthorization={handleReprintAuthorization}
                            handleTiketVoid={openTiketVoid}
                            disabledTicketVoid={!data?.canBeCanceled}
                        />
                        <div className={classes.bodyWrapContainer}>
                            <Box className={classes.content}>
                                <Grid container spacing={3} alignItems="flex-start">
                                    <Grid item md={12} style={{ width: '100%' }}>
                                        <Accordion id={'main'} expanded={expandedBlock.main}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                onClick={() => onExpansionClick('main')}
                                            >
                                                <div className={clsx(classes.mainBlockHeadCell, classes.mainBlockBetTypeCell, classes.mainBlockHeadCellWide)}>
                                                    <Typography variant="body2" className={classes.mainBlockHeadCellName}>
                                                        {translations['emp-reports-ticket-bet-type']}
                                                    </Typography>
                                                    <Typography variant={'h5'} className={classes.betTypeDescription}>
                                                        {data.hasSgpxSelections &&
                                                            <BadgeIcon>
                                                                <SgpxIcon />
                                                            </BadgeIcon>
                                                        }
                                                        {data.betType === BET_TYPE.PROGRESSIVE_PARLAY &&
                                                            <BadgeIcon>
                                                                <ProgIcon />
                                                            </BadgeIcon>
                                                        }
                                                        <span>{data.betTypeDescriptions ? getBetDescriptionsString(data.betTypeDescriptions, translations) : ''}</span>
                                                    </Typography>
                                                </div>
                                                <div className={classes.mainBlockHeadCell}>
                                                    <Typography variant="body2" className={classes.mainBlockHeadCellName} noWrap>
                                                        {translations['emp-reports-ticket-cost']}
                                                    </Typography>
                                                    <Typography variant={'h5'} noWrap>
                                                        {data.ticketId &&
                                                            formatNumberToCurrencyString(
                                                                data.costAmount,
                                                                numberStyles(globalSettings?.settings?.Currency),
                                                                globalSettings?.settings?.CurrencySign,
                                                                globalSettings?.settings?.CurrencyFormat
                                                            )
                                                        }
                                                    </Typography>
                                                </div>
                                                <div className={classes.mainBlockHeadCell}>
                                                    <Typography variant="body2" className={classes.mainBlockHeadCellName} noWrap>
                                                        {getOddsTitle(data.betTypeDescriptions, translations)}
                                                    </Typography>
                                                    <Typography variant={'h5'} noWrap>
                                                        {data.ticketId &&
                                                            data.displayMaxOdds
                                                        }
                                                    </Typography>
                                                </div>
                                                <div className={classes.mainBlockHeadCell}>
                                                    <Typography variant="body2" className={classes.mainBlockHeadCellName} noWrap>
                                                        {translations['emp-reports-ticket-to-win']}
                                                    </Typography>
                                                    <Typography variant={'h5'} noWrap>
                                                        {data.ticketId &&
                                                            formatNumberToCurrencyString(
                                                                data.toWinAmount,
                                                                numberStyles(globalSettings?.settings?.Currency),
                                                                globalSettings?.settings?.CurrencySign,
                                                                globalSettings?.settings?.CurrencyFormat
                                                            )
                                                        }
                                                    </Typography>
                                                </div>
                                                <div className={classes.mainBlockHeadCell}>
                                                    <Typography variant="body2" className={classes.mainBlockHeadCellName} noWrap>
                                                        {translations['emp-reports-ticket-to-pay']}
                                                    </Typography>
                                                    <Typography variant={'h5'} noWrap>
                                                        {data.ticketId &&
                                                            formatNumberToCurrencyString(
                                                                data.toPayAmount,
                                                                numberStyles(globalSettings?.settings?.Currency),
                                                                globalSettings?.settings?.CurrencySign,
                                                                globalSettings?.settings?.CurrencyFormat
                                                            )
                                                        }
                                                    </Typography>
                                                </div>
                                            </AccordionSummary>

                                            <AccordionDetails>
                                                <Box padding={3} className={clsx(classes.blockDetailsContainer, classes.mainBlockDetailsContainer)}>
                                                    {ticketDetailsMainDetails.map((property, id) => {
                                                        const show =
                                                            (property.permission &&
                                                                globalSettings?.settings?.TicketsReportSettings[property.permission]) ?? true;

                                                        return (
                                                            show &&
                                                                <div key={id} className={classes.mainBlockDetailsBox}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        className={classes.mainBlockDetailsName}
                                                                    >
                                                                        {translations[property.key]}
                                                                    </Typography>
                                                                    <Typography variant="body2" className={classes.mainBlockDetailsDesc}>
                                                                        {data.ticketId
                                                                            ? getPropertyValue(property, data, dateTimeFormat, timeZone, globalSettings)
                                                                            : '-'
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                        );
                                                    })}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                        {
                                            data?.printouts?.some(printout => hasKeyWithTrueValue(printout, detailsAuthorizeReprintKeysForCheck)) &&
                                                <TicketDetailsPrintout
                                                    data={data}
                                                    isExpanded={expandedBlock.printout}
                                                    onExpansionClick={onExpansionClick}
                                                />
                                        }
                                        {
                                            (permissions.reportTicketsTicketVoid ||
                                                data.betStatus === BET_STATUS.CANCELED ||
                                                data.betStatus === BET_STATUS.VOIDED) &&
                                                data?.canceledInformation &&
                                                    <TicketDetailsCancel
                                                        data={data}
                                                        isExpanded={expandedBlock.cancel}
                                                        onExpansionClick={onExpansionClick}
                                                    />
                                        }
                                    </Grid>
                                    <Grid item md={12} style={{ width: '100%' }}>
                                        <TicketDetailsTable
                                            selectionNodes={data.selections || []}
                                            betType={data.betType}
                                            totalOdds={data.displayMaxOdds}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </Paper>
                    <Modal isOpen={isOpenReprintAuthorizationModal} onClose={onCloseReprintAuthorizationModal} data-a="reprint-authorization-modal">
                        <DialogTitle id="alert-dialog-title">{translations['emp-reports-ticket-authorize-reprint-modal-title']}</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <DialogContentText id="alert-dialog-description">
                                {translations['emp-reports-ticket-authorize-reprint-modal-reason']}
                            </DialogContentText>
                        </DialogContent>
                        <Box className={classes.modalBox} mb={1}>
                            <List disablePadding>
                                {ticketDetailsAuthorizeReprintReason.map((reason) => (
                                    <ListItem
                                        className={classes.listItem}
                                        key={reason.id}
                                        dense
                                        onClick={() => setAuthorizeReprintReason(reason.id)}
                                    >
                                        <Radio
                                            color={'primary'}
                                            checked={reason.id === authorizeReprintReason}
                                            name="item.id"
                                        />
                                        {translations[reason.label]}
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                        {/* <Box className={classes.modalBox} mb={1}>
                            <Box className={classes.inputWrap}>
                                <TextField
                                    label={translations['emp-reports-ticket-authorize-reprint-modal-comment']}
                                    multiline
                                    variant="outlined"
                                    rows="4"
                                    fullWidth
                                    value={authorizeReprintComment}
                                    onChange={handleCommentChange}
                                    helperText={translations['gen-optional']}
                                />
                            </Box>
                        </Box> */}
                        <DialogActions>
                            <ButtonPrimaryText onClick={onCloseReprintAuthorizationModal} data-a="emp-reports-ticket-authorize-reprint-modal-button-no">
                                {translations['gen-close']}
                            </ButtonPrimaryText>
                            <ButtonPrimaryText
                                onClick={handleSubmitReprintAuthorization}
                                data-a="emp-reports-ticket-authorize-reprint-modal-button-yes"
                                disabled={!authorizeReprintReason}
                            >
                                {translations['gen-proceed']}
                            </ButtonPrimaryText>
                        </DialogActions>
                    </Modal>
                    <Modal isOpen={isOpenTicketVoidModal} onClose={onCloseTicketVoidModal} data-a="ticket-void-modal">
                        <DialogTitle id="ticket-void-modal-title">{translations[TicketVoidModal.title]}</DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <DialogContentText id="ticket-void-modal-description">
                                {translations[TicketVoidModal.description]}
                            </DialogContentText>
                        </DialogContent>
                        <Box className={classes.modalBox} mb={1}>
                            <List disablePadding>
                                {globalSettings?.settings?.TicketsReportSettings?.CancelReasons.map((reason) =>
                                    (reason.Id !== CANCEL_REASONS.CanceledByLBM && reason.Id !== CANCEL_REASONS.EventCanceled) && (
                                        <ListItem
                                            className={classes.listItem}
                                            key={reason.Id}
                                            dense
                                            onClick={() => setTicketVoidReasonId(reason.Id)}
                                        >
                                            <Radio
                                                color={'primary'}
                                                checked={reason.Id === ticketVoidReasonId}
                                                name={`ticket-void-reason-${reason.Reason}`}
                                            />
                                            {translations[`ticket-void-reason-${reason.Reason}`]}
                                        </ListItem>
                                    ))}
                            </List>
                        </Box>
                        <DialogActions>
                            <Button onClick={onCloseTicketVoidModal} color="primary" data-a="emp-reports-ticket-void-modal-button-no">
                                {translations['gen-close']}
                            </Button>
                            <Button
                                onClick={handleSubmitTicketVoid}
                                color="primary"
                                autoFocus
                                data-a="emp-reports-ticket-void-modal-button-proceed"
                                disabled={ticketVoidReasonId === null}
                            >
                                {translations['gen-proceed']}
                            </Button>
                        </DialogActions>
                    </Modal>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={showNotification}
                        autoHideDuration={6000}
                        onClose={handleCloseNotification}
                    >
                        <MuiAlert
                            onClose={handleCloseNotification}
                            severity={requestInfo.status}
                            elevation={6}
                            variant="filled"
                        >
                            {translations[requestInfo.message]}
                        </MuiAlert>
                    </Snackbar>
                </>)}
        </div>
    );
};

export default withRouter(TicketDetails);
